import { MutationHookOptions, useMutation } from '@apollo/client'

import {
  UPSERT_ONE_LEAD,
  UpsertOneLeadPayload,
  UpsertOneLeadVariables,
} from 'queries/lead'

const useLeadMutate = (
  options?: MutationHookOptions<UpsertOneLeadPayload, UpsertOneLeadVariables>,
) => {
  const [upsertLead, { loading, error }] = useMutation<
    UpsertOneLeadPayload,
    UpsertOneLeadVariables
  >(UPSERT_ONE_LEAD, options)

  return {
    upsertLead,
    loading,
    error,
  }
}
export default useLeadMutate
