import React from 'react'

import 'twin.macro'
import { BLOCKS } from '@contentful/rich-text-types'
import { Text as ContentfulText } from '@contentful/rich-text-types/dist/types/types'
import { motion } from 'framer-motion'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import useDisplay from '../hooks/useDisplay'
import { pressContainer, pressItems } from '../motion-variants'
import { Section } from '../section'

interface ReviewsProps {
  data: {
    contentSlots: {
      markdown: RenderRichTextData<ContentfulRichTextGatsbyReference>
      meta?: {
        date?: string
      }
      photos?: {
        title?: string
        file: { url: string }
      }[]
      slotTitle: string
    }[]
  }
}
const Reviews = ({ data }: ReviewsProps) => {
  const intersectionRef = React.useRef(null)
  const showIt = useDisplay(intersectionRef)

  return (
    <Section tw="mt-44" padding="none">
      <div tw="grid grid-cols-6 items-center w-full md:grid-cols-16">
        <div tw="col-span-full h-full md:(col-start-2 col-end-16)">
          <div
            tw="justify-center items-end relative md:(flex flex-wrap)"
            ref={intersectionRef}
          >
            <motion.div
              variants={pressContainer}
              initial="hidden"
              animate={showIt ? 'show' : 'hidden'}
              className="scroll-snap-x"
              tw="px-6 flex space-x-6 md:(justify-center) lg:(space-x-8)"
            >
              {data.contentSlots.map((review, index: number) => {
                return (
                  <motion.div
                    key={index}
                    variants={pressItems}
                    tw="w-10/12 flex flex-col shrink-0 items-start relative justify-between md:(w-4/12)"
                  >
                    <div tw="w-full text-left h-[max-content] pb-6">
                      <img
                        alt={review.photos?.[0].title}
                        tw="w-auto max-h-12"
                        src={review.photos?.[0].file.url}
                      />
                    </div>
                    <div tw="space-y-4 pr-8">
                      {renderRichText(review.markdown, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => {
                            const { value: textValue } = node
                              .content[0] as ContentfulText
                            if (node.nodeType === 'paragraph' && textValue) {
                              return (
                                <p tw="text-gray-600 text-sm max-w-xs leading-normal">
                                  {children}
                                </p>
                              )
                            }
                          },
                        },
                        renderText: (text) =>
                          text
                            .split('\n')
                            .flatMap((text, i) => [
                              i > 0 && <br key={i} />,
                              text,
                            ]),
                      })}
                    </div>
                  </motion.div>
                )
              })}
            </motion.div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Reviews
