import { useStaticQuery, graphql } from 'gatsby'

export const useCommonQuestions = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "7aZgK4qBeiq184Zdd3ItKF" } }
      ) {
        data: edges {
          node {
            title
            description
            sectionModules {
              moduleTitle
              moduleMisc {
                identifier
              }
              contentSlots {
                slotTitle
                textparagraphquote {
                  textparagraphquote
                }
                markdown {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
