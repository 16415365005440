import { gql } from '@apollo/client'

import { Lead } from 'types/lead'

export const UPSERT_ONE_LEAD = gql`
  mutation upsertOneLead(
    $create: LeadCreateInput!
    $update: LeadUpdateInput!
    $where: LeadWhereUniqueInput!
  ) {
    upsertOneLead(create: $create, update: $update, where: $where) {
      id
      hubspotId
    }
  }
`

export interface UpsertOneLeadPayload {
  upsertOneLead: Lead
}

export interface UpsertOneLeadVariables {
  create: Omit<Lead, 'id' | 'createdAt' | 'updatedAt'>
  update: Partial<Lead>
  where: {
    email: string
  }
}
