import React from 'react'

import { PlusIcon } from '@radix-ui/react-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { navigate } from 'gatsby'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import tw from 'twin.macro'

import { useCommonQuestions } from 'content-queries/mx/common-questions'

import { Container, Content, PhaseTitle } from './container'
import { MxButton as Button } from '../components/section-components'
import { Section } from '../section'

interface QuestionProps {
  slotTitle: string
  markdown?: RenderRichTextData<ContentfulRichTextGatsbyReference>
  textparagraphquote?: { textparagraphquote?: string }
}

interface QuestionsProps {
  questions?: QuestionProps[]
}
const Question = ({
  question,
  isLast,
}: {
  isLast?: boolean
  question: QuestionProps
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <motion.header
        initial={false}
        tw="cursor-pointer w-full px-4 py-9 border-gray-300 border-b border-dashed flex justify-between items-center text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:border-transparent hover:bg-gray-50 transform transition-colors"
        onClick={() => setExpanded(expanded ? false : true)}
        css={[!isLast && tw`border-b`]}
      >
        <p tw="text-xl font-thin text-gray-700 xs:(text-2xl)">
          {question.slotTitle}
        </p>
        <div tw="text-gray-400" css={[expanded && tw`transform rotate-45`]}>
          <PlusIcon color="currentColor" width="24px" height="24px" />
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4 }}
          >
            <div tw="flex bg-gray-50 px-4 py-4">
              <div tw="w-full md:(w-9/12)">
                {question.markdown ? (
                  <div tw="prose font-light">
                    {renderRichText(question?.markdown)}
                  </div>
                ) : (
                  <div
                    tw="prose mx-auto font-light"
                    dangerouslySetInnerHTML={{
                      __html:
                        question?.textparagraphquote?.textparagraphquote ?? '',
                    }}
                  />
                )}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}

const CommonQuestions = ({ questions }: QuestionsProps) => {
  let commonQuestions = questions
  const { data } = useCommonQuestions()

  if (!commonQuestions) {
    const {
      node: {
        sectionModules: [{ contentSlots }],
      },
    } = data[0]
    commonQuestions = contentSlots
  }

  return (
    <Section padding="top">
      <Container>
        <Content
          tw="row-span-1 px-0 pb-24"
          css={questions && [tw`md:!col-start-2`]}
        >
          <PhaseTitle tw="px-4 mb-8 md:(mb-16 max-w-none)">FAQs</PhaseTitle>
          {commonQuestions?.map((question: QuestionProps, i: number) => {
            return (
              <Question
                key={i}
                isLast={i === Number(commonQuestions?.length) - 1}
                question={question}
              />
            )
          })}
          {!questions && (
            <div tw="pt-24 px-4">
              <Button
                kind="link"
                onClick={() => {
                  navigate('/faq')
                }}
              >
                See all FAQs
              </Button>
            </div>
          )}
        </Content>
      </Container>
    </Section>
  )
}

export { CommonQuestions }
